<template>
  <v-app>
      
    <v-main class="main">
      <v-row style="position:absolute; width: 100%; top:50%; -ms-transform: translateY(-50%); transform: translateY(-50%);">
        <v-col cols="10" md="8" lg="6"  offset="1" offset-md="2" offset-lg="3" style="">
          <v-img src="@/assets/earth2.png"></v-img>
        </v-col>

      </v-row>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style scoped>

.main {
  background: url("./assets/space.jpg") no-repeat center center fixed;
  background-size: cover;
}
</style>